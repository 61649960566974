.row {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  min-height: 90vh;

  &__footer {
    min-height: 20vh;
  }
}
