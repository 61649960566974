// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------

@use '../abstracts/index' as *;

.footer {
  min-height: 20vh;
  color: $white;
  text-align: center;
  background-color: #343445;
}

.page__navbar {
  .footer {
    color: rgb(160, 171, 181);
    background-color: rgb(4, 34, 48);
  }
}
