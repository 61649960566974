// -----------------------------------------------------------------------------
// This file contains all styles related to the navbar of the site/application.
// -----------------------------------------------------------------------------
@use '../abstracts/index' as *;

#navbar {
  --navbar-height: 3.5rem;
}

.navbar {
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: var(--navbar-height);

  a.navbar-link {
    border-bottom: none;
  }

  .navbar-menu {
    z-index: 10;
  }

  @media only screen and (min-width: 768px) {
    position: fixed;
  }
}

.navbar-item {
  width: 100%;
  margin: .4em;
}

.home-link,
.navbar-link {
  display: flex;
  align-items: center;
  font-weight: 400;
  color: var(--navbar-text-color);
  text-decoration: none;
  transition: color .2s ease-in-out;
  transition:
    background-color .2s ease-in-out,
    color .2s ease-in-out;
}

.home-link:focus,
.home-link:hover {
  color: var(--navbar-text-color-focus);
}

.navbar-link {
  justify-content: center;
  width: 100%;
  padding: .4em .8em;
  // border-radius: 5px;
}

.navbar-link:focus,
.navbar-link:hover {
  color: var(--navbar-text-color-focus);
  text-decoration: none;
  background-color: var(--navbar-bg-contrast);
}

.navbar-link:focus {
  border: 1px dotted var(--navbar-text-color-focus);
}

.navbar-logo {
  width: 30px;
  height: 30px;
  margin-right: .5em;
  background-color: var(--navbar-text-color-focus);
  border-radius: 50%;
}

.navbar-toggle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  color: $white;
  cursor: pointer;
  background-color: transparent;
  border: none;

  &:hover,
  &:focus {
    border: 2px solid var(--navbar-text-color-focus);
    border-radius: 4px;
  }

  &:focus {
    border: 2px dotted var(--navbar-text-color-focus);
  }
}

.icon-bar {
  z-index: 100;
  display: block;
  width: 28px;
  height: 4px;
  margin: 2px;
  background-color: var(--icon-bar-bg-color);
  transition:
    background-color .2s ease-in-out,
    transform .2s ease-in-out,
    opacity .2s ease-in-out;
}

.navbar-toggle:focus .icon-bar,
.navbar-toggle:hover .icon-bar {
  background-color: var(--navbar-text-color-focus);
}

#navbar.is-active .navbar-toggle .icon-bar:first-child,
#navbar.is-active .navbar-toggle .icon-bar:last-child {
  position: absolute;
  width: 30px;
  margin: 0;
}

#navbar.is-active .navbar-toggle .icon-bar:first-child {
  transform: rotate(45deg);
}

#navbar.is-active .navbar-toggle .icon-bar:nth-child(2) {
  opacity: 0;
}

#navbar.is-active .navbar-toggle .icon-bar:last-child {
  transform: rotate(-45deg);
}

#navbar.is-active .icon-bar {
  background-color: var(--navbar-text-color);
}

.navbar-menu {
  position: fixed;
  top: -1.25rem; //var(--navbar-height);
  right: 0;
  bottom: 0;
  left: 0;
  visibility: hidden;
  opacity: 0;
  transition:
    opacity .2s ease-in-out,
    visibility .2s ease-in-out;
}

#navbar.is-active .navbar-menu {
  visibility: visible;
  background-color: hsla(0, 0%, 0%, .4);
  opacity: 1;
}

.navbar-links {
  position: absolute;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 0;
  overflow: hidden;
  list-style-type: none;
  background-color: var(--navbar-bg-color);
  // box-shadow: 0 0 20px hsla(0, 0%, 0%, 0.3);
  opacity: .9;
}

#navbar.is-active .navbar-links {
  max-height: none;
  padding: 1em;
}

@media screen and (min-width: 768px) {
  .navbar-toggle {
    display: none;
  }

  #navbar .navbar-menu,
  #navbar.is-active .navbar-menu {
    position: static;
    display: block;
    height: 100%;
    visibility: visible;
    opacity: 1;
  }

  #navbar .navbar-links,
  #navbar.is-active .navbar-links {
    position: static;
    flex-direction: row;
    width: 100%;
    height: 100%;
    max-height: -webkit-max-content;
    max-height: -moz-max-content;
    max-height: max-content;
    padding: 0;
    margin: 0;
    list-style-type: none;
    box-shadow: none;
  }

  #navbar .navbar-link:last-child {
    margin-right: 0;
  }
}

.navbar-container {
  background-color: transparent;

  @media only screen and (min-width: 768px) {
    justify-content: space-around;
    height: 3.5rem;
    background-color: hsl(94, 50%, 55%);
    box-shadow: 0 2px 4px hsla(0, 0%, 0%, .15);
  }
}
