/// Disable smooth scrolling when users have `prefers-reduced-motion` enabled
@media screen and (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}

/// @media queries here should be site wide and not page specific.
/// These rules are included *before* page rules not after.
/// Page specific @media queries can be nested within the rule to be overridden.

// @media only screen and (min-width: 576px) {

// }

// @media only screen and (min-width: 768px) {

// }

// @media only screen and (min-width: 992px) {

// }

// @media only screen and (min-width: 1200px) {

// }

// @media only screen and (min-width: 1400px) {

// }
