
/// Regular font family
/// @type List
// $text-font-stack: 'Open Sans', 'Helvetica Neue Light', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif !default;
$text-font-stack: system-ui, -apple-system, blinkmacsystemfont, 'Segoe UI', roboto, oxygen-sans, ubuntu, cantarell, 'Helvetica Neue', arial, 'Noto Sans', 'Liberation Sans', sans-serif !default;

/// Code (monospace) font family
/// @type List
// $code-font-stack: 'Courier New', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', 'Monaco', monospace !default;
$code-font-stack: sfmono-regular, menlo, monaco, consolas, 'Liberation Mono', 'Courier New', 'DejaVu Sans Mono', monospace !default;

h1,
h2,
h3 {
  margin-bottom: 1rem;
  font-weight: 500;
  line-height: 1.5;
}

h1 {
  font-size: calc(2rem + 1vw);
}

h2 {
  font-size: calc(1.5rem + 1vw) !important;
}

.section {
  h1 {
    font-family: 'Montserrat', sans-serif;
    text-align: center;
  }

  h2 {
    font-family: 'Cherry Cream Soda', cursive;
    color: var(--dark-green);
    text-align: center;
  }
}
