// -----------------------------------------------------------------------------
// This file contains all application-wide color variables.
// -----------------------------------------------------------------------------

:root {
  --dark-green: hsl(93, 51%, 10%);
  --goldenrod: hsl(43, 74%, 49%);
  --goldenrod-a20: hsla(43, 74%, 49%, .2);
  --lime-green-a30: hsla(94, 50%, 55%, .3);
  --icon-bar-bg-color: hsl(0, 0%, 100%);
  --navbar-bg-color: hsl(94, 50%, 55%);
  --navbar-text-color: hsl(93, 51%, 10%);
  --navbar-text-color-focus: hsl(0, 0%, 100%);
  // --navbar-bg-contrast: hsl(0, 0%, 25%);
  --navbar-bg-contrast: hsl(93, 51%, 10%);
}

/// Body background color
/// @type Color
$body-bg-color: hsl(0, 0%, 98%) !default;

/// Dark green background
/// @type Color
$bg-dark-green: hsl(93, 51%, 10%);

/// Copy text color
/// @type Color
$text-color: hsl(0, 0%, 20%) !default;

/// Main brand color
/// @type Color
// $brand-color: hsl(94, 50%, 55%) !default;
// $brand-color: hsl(94, 52%, 32%) !default;
// $brand-color: #c74a59 !default;
// $brand-color: #7a2731 !default;
// $brand-color: #c76240 !default;
$brand-color: var(--dark-green);

/// Light grey
/// @type Color
$light-grey: hsl(0, 0%, 93%) !default;

/// Medium grey
/// @type Color
$mid-grey: hsl(0, 0%, 60%) !default;

/// Dark grey
/// @type Color
$dark-grey: hsl(0, 0%, 27%) !default;

/// White and Black
/// @type Color
$white: hsl(0, 0%, 100%) !default;
$black: hsl(0, 0%, 0%) !default;

/// Primary colors
/// @type Color
$red: hsl(0, 100%, 50%) !default;
$green: hsl(120, 100%, 50%) !default;
$blue: hsl(240, 100%, 50%) !default;
$test-color:  hsl(120, 100%, 10%);
/// Print colors
/// @type Color
$cyan: hsl(180, 100%, 50%) !default;
$magenta: hsl(300, 100%, 50%) !default;
$yellow: hsl(60, 100%, 50%) !default;

/// Additional colors
/// @type Color
$orange: hsl(30, 100%, 50%) !default;
$indigo: hsl(275, 100%, 25%) !default;
$violet: hsl(270, 100%, 50%) !default;

/// rebeccapurple because ...
/// https://meyerweb.com/eric/thoughts/2014/06/19/rebeccapurple/
/// @type Color
$rebeccapurple: hsl(270, 50%, 40%) !default;
