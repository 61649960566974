// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------

@use '../abstracts/index' as *;

/**
 * Clear inner floats
 */
.clearfix::after {
  display: table;
  clear: both;
  content: '';
}

/**
 * Main content containers: .container and .row
 * 1. Make the container full-width with a maximum width
 * 2. Leave some space on the edges, especially valuable on small screens
 * 3. Center it in the viewport
 */
.container {
  width: 100%; /* 1 */
  max-width: $max-width; /* 1 */
  height: auto;
  margin-right: auto; /* 3 */
  margin-left: auto; /* 3 */
}

/**
 * Set maximum number of characters for width of lines.
 * Especially important on large to extra-large screens.
 */
.row {
  padding-top: 2rem;
  padding-right: 1.4rem; /* 2 */
  padding-bottom: 2rem;
  padding-left: 1.4rem; /* 2 */
  margin-right: auto; /* 3 */
  margin-left: auto; /* 3 */
  // Set `max-width` to limit width on larger screens
  // while not requiring a minimum width on small screens.
  &--narrow {
    max-width: 65ch;
  }
}

/**
 * Hide text while making it readable for screen readers
 * 1. Needed in WebKit-based browsers because of an implementation bug;
 *    See: https://code.google.com/p/chromium/issues/detail?id=457146
 */
.hide-text {
  padding: 0; /* 1 */
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
}

/**
 * Hide element while making it readable for screen readers
 * Shamelessly borrowed from HTML5Boilerplate:
 * https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L119-L133
 */
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  border: 0;
}
