@use '../abstracts/index' as *;

.banner__heading {
  h1 {
    margin-bottom: 2rem;
    font-family: 'Kaushan Script', cursive;
    font-size: calc(2.5rem + 1vw);
    color: $white;
    text-shadow: 2px 2px 2px mix($white, $black, 10%);
  }

  .banner a {
    border-bottom: none;

    &:hover {
      background-color: none;
    }
  }

  .icon-chevron-down {
    width: 3.5rem;
    height: 3.5rem;
    fill: $white;
  }
}

a .icon-chevron-down {
  border-bottom: none;

  &:focus {
    border: 2px dotted $white;
    border-radius: 5px;
  }
}

.icon-envelope-o {
  width: 6rem;
  height: 6rem;
}

.section__events {
  background-color: hsl(94, 38%, 90%); /* #D4CF4E; /*hsl(94, 50%, 80%); */

  h2 {
    color: $black;

    &::before {
      margin-right: 10px;
      content:
        url('data:image/svg+xml; utf8, <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><title>calendar</title><path d="M29.334 3h-4.334v-2c0-0.553-0.447-1-1-1s-1 0.447-1 1v2h-6v-2c0-0.553-0.448-1-1-1s-1 0.447-1 1v2h-6v-2c0-0.553-0.448-1-1-1s-1 0.447-1 1v2h-4.333c-1.473 0-2.667 1.193-2.667 2.666v23.667c0 1.473 1.194 2.667 2.667 2.667h26.667c1.473 0 2.666-1.194 2.666-2.667v-23.667c0-1.473-1.193-2.666-2.666-2.666zM30 29.333c0 0.368-0.299 0.667-0.666 0.667h-26.667c-0.368 0-0.667-0.299-0.667-0.667v-23.667c0-0.367 0.299-0.666 0.667-0.666h4.333v2c0 0.553 0.448 1 1 1s1-0.447 1-1v-2h6v2c0 0.553 0.448 1 1 1s1-0.447 1-1v-2h6v2c0 0.553 0.447 1 1 1s1-0.447 1-1v-2h4.334c0.367 0 0.666 0.299 0.666 0.666v23.667zM7 12h4v3h-4zM7 17h4v3h-4zM7 22h4v3h-4zM14 22h4v3h-4zM14 17h4v3h-4zM14 12h4v3h-4zM21 22h4v3h-4zM21 17h4v3h-4zM21 12h4v3h-4z"></path></svg>');
    }
  }

  a:hover,
  a:focus {
    padding: 0;
    background-color: transparent;
    border: none;
  }

  &--image {
    margin: 2rem auto;

    img {
      width: 100%;
      max-width: 100%;
    }
  }
}

.section__in-touch {
  background-color: hsl(58, 38%, 85%);

  h1::before {
    margin-right: 10px;
    content:
      url('data:image/svg+xml; utf8, <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="41" height="32" style="fill: hsl(93, 51%, 10%);" viewBox="0 0 36 28"><title>handshake-o</title> <path d="M3 18c1.313 0 1.313-2 0-2s-1.313 2 0 2zM26.016 17.094c-1.234-1.609-2.438-3.25-3.844-4.719l-1.953 2.188c-1.719 1.953-4.797 1.906-6.469-0.094-1.188-1.437-1.188-3.5 0.031-4.922l2.766-3.219c-0.969-0.5-2.141-0.328-3.187-0.328-0.922 0-1.813 0.375-2.469 1.031l-2.469 2.469h-2.422v8.5c0.688 0 1.312-0.094 1.859 0.438l4.641 4.562c0.953 0.922 2.187 1.734 3.547 1.734 0.703 0 1.453-0.234 1.953-0.734 1.172 0.406 2.531-0.25 2.891-1.453 0.75 0.063 1.437-0.172 1.984-0.688 0.359-0.328 0.828-0.984 0.781-1.5 0.141 0.141 0.484 0.156 0.672 0.156 1.859 0 2.828-1.953 1.687-3.422zM28.5 18h1.5v-8h-1.453l-2.453-2.812c-0.656-0.75-1.641-1.188-2.641-1.188h-2.609c-0.875 0-1.719 0.391-2.281 1.047l-3.266 3.797c-0.578 0.688-0.578 1.656-0.016 2.344 0.891 1.062 2.531 1.078 3.453 0.047l3.016-3.406c0.719-0.797 2.031-0.047 1.703 0.969 0.594 0.688 1.234 1.359 1.813 2.047 0.781 0.969 1.531 1.984 2.297 2.969 0.484 0.625 0.844 1.375 0.938 2.188zM33 18c1.313 0 1.313-2 0-2s-1.313 2 0 2zM36 9v10c0 0.547-0.453 1-1 1h-6.781c-0.562 1.359-1.797 2.266-3.234 2.469-0.672 0.984-1.703 1.734-2.859 1.984-0.859 1.094-2.25 1.75-3.641 1.656-2.578 1.453-5.484 0.187-7.406-1.703l-4.484-4.406h-5.594c-0.547 0-1-0.453-1-1v-10.5c0-0.547 0.453-1 1-1h6.578c1.813-1.813 3.062-3.5 5.781-3.5h1.828c1.016 0 2 0.313 2.828 0.875 0.828-0.562 1.813-0.875 2.828-0.875h2.609c2.969 0 4.203 1.937 6 4h5.547c0.547 0 1 0.453 1 1z"></path></svg>');
  }
}

.section__facebook {
  color: $white;
  background-color: mix($white, $bg-dark-green, 5%);
  background-image: url('../img/brisbanests-crew_1600x600.jpg');
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  background-blend-mode: multiply;

  h2 {
    color: $white;

    &::before {
      margin-right: 10px;
      content:
        url('data:image/svg+xml; utf8, <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="white" style="fill: hsl(0, 0, 100%);" viewBox="0 0 32 32"><title>facebook2</title><path d="M29 0h-26c-1.65 0-3 1.35-3 3v26c0 1.65 1.35 3 3 3h13v-14h-4v-4h4v-2c0-3.306 2.694-6 6-6h4v4h-4c-1.1 0-2 0.9-2 2v2h6l-1 4h-5v14h9c1.65 0 3-1.35 3-3v-26c0-1.65-1.35-3-3-3z"></path></svg>');
    }
  }

  &--title {
    color: $white;
  }

  a {
    color: $white;

    &:hover,
    &:focus {
      background-color: transparent;
    }

    &:hover {
      border: 1px solid $white;
    }

    &:focus {
      border: 1px dotted $white;
    }
  }
}

.section__mailchimp {
  background-color: hsl(52, 55%, 90%);

  h2::before {
    margin-right: 10px;
    content:
      url('data:image/svg+xml; utf8, <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="36" height="36" style="fill: hsl(93, 51%, 10%);" viewBox="0 0 32 32"><title>megaphone</title><path d="M25 0c-2.053 0-3.666 1.41-4.824 3.586l-0.021-0.012c-1.682 3.293-4.186 5.426-6.929 5.426h-9.226c-2.243 0-4 2.197-4 5 0 2.805 1.757 5 4 5 1.103 0.004 1.995 0.896 1.995 2v9c0 1.105 0.896 2 2 2h4c1.104 0 2-0.895 2-2v-1c0-1-1-1.447-1-2v-7c0-0.023 0.016-0.039 0.018-0.062 0.010-0.143 0.049-0.275 0.112-0.395 0.018-0.033 0.037-0.059 0.059-0.088 0.080-0.119 0.181-0.217 0.304-0.293 0.006-0.004 0.008-0.010 0.014-0.014 0.004 0 0.007-0.004 0.011-0.004 0.080-0.045 0.176-0.055 0.266-0.080 2.524 0.268 4.809 2.305 6.377 5.373l0.025-0.012c1.159 2.169 2.768 3.575 4.819 3.575 4.596 0 7-7.043 7-14s-2.404-14-7-14zM20 14c0-1.037 0.061-2.039 0.164-3h2.836c1.104 0 2 1.344 2 3 0 1.658-0.896 3-2 3h-2.836c-0.103-0.961-0.164-1.961-0.164-3zM2 14c0-1.656 0.896-3 2-3h7.014c-0.62 0.729-1.014 1.787-1.014 3 0 1.215 0.394 2.273 1.014 3h-7.014c-1.104 0-2-1.342-2-3zM11.995 30h-4v-9c0-0.729-0.195-1.41-0.537-2h0.698v0.012h3.008c-0.107 0.311-0.169 0.641-0.169 0.988v7c0 0.963 0.538 1.604 0.859 1.986 0.044 0.053 0.096 0.107 0.141 0.166v0.848zM13.226 17.012h-0.226v-0.012c-1.104 0-2-1.342-2-3 0-1.656 0.896-3 2-3h0.226c1.886 0 3.652-0.742 5.206-2.018-0.286 1.61-0.432 3.315-0.432 5.018 0 1.709 0.146 3.42 0.434 5.031-1.555-1.277-3.322-2.019-5.208-2.019zM25 26c-2.018 0-3.75-2.871-4.541-7h2.541c2.242 0 4-2.195 4-5 0-2.803-1.758-5-4-5h-2.541c0.791-4.127 2.523-7 4.541-7 2.762 0 5 5.373 5 12s-2.238 12-5 12z"></path></svg>');
  }
}

.section__contact {
  background-color: hsl(33, 53%, 85%);

  h2::before {
    margin-right: 10px;
    content:
      url('data:image/svg+xml; utf8, <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="34" height="34" style="fill: hsl(93, 51%, 10%);" viewBox="0 0 28 28"><title>envelope-o</title> <path d="M26 23.5v-12c-0.328 0.375-0.688 0.719-1.078 1.031-2.234 1.719-4.484 3.469-6.656 5.281-1.172 0.984-2.625 2.188-4.25 2.188h-0.031c-1.625 0-3.078-1.203-4.25-2.188-2.172-1.813-4.422-3.563-6.656-5.281-0.391-0.313-0.75-0.656-1.078-1.031v12c0 0.266 0.234 0.5 0.5 0.5h23c0.266 0 0.5-0.234 0.5-0.5zM26 7.078c0-0.391 0.094-1.078-0.5-1.078h-23c-0.266 0-0.5 0.234-0.5 0.5 0 1.781 0.891 3.328 2.297 4.438 2.094 1.641 4.188 3.297 6.266 4.953 0.828 0.672 2.328 2.109 3.422 2.109h0.031c1.094 0 2.594-1.437 3.422-2.109 2.078-1.656 4.172-3.313 6.266-4.953 1.016-0.797 2.297-2.531 2.297-3.859zM28 6.5v17c0 1.375-1.125 2.5-2.5 2.5h-23c-1.375 0-2.5-1.125-2.5-2.5v-17c0-1.375 1.125-2.5 2.5-2.5h23c1.375 0 2.5 1.125 2.5 2.5z"></path></svg>');
  }
}

.section__parallax {
  min-height: 60vh;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  @media only screen and (min-width: 768px) {
    min-height: 65vh;
  }

  @media only screen and (min-width: 992px) {
    min-height: 70vh;
    background-attachment: fixed;
  }

  &--aloe {
    background-image: url('../img/Aloeforwebslider.jpg');
  }

  &--thermo {
    background-image: url('../img/thermoslider.jpg');
  }

  &--group {
    // background-image: url('../img/group5.jpg');
    // background-image: url('../img/group3slider.jpg');
    background-image: url('../img/QLDpresteamslider.jpg');

    @media only screen and (min-width: 768px) {
      min-height: 75vh !important;
    }

    @media only screen and (min-width: 992px) {
      min-height: 80vh !important;
      background-attachment: fixed;
    }
  }
}

.row__in-touch {
  // display: flex;
  // flex-direction: column;
  // flex-wrap: wrap;
  // align-items: center;
  // justify-content: center;
  // // min-height: 40vh;

  p {
    text-align: center;
  }
}

.row__facebook {
  // display: flex;
  // flex-direction: column;
  // flex-wrap: wrap;
  // align-items: center;
  // justify-content: center;
  // min-height: 40vh;

  p {
    text-align: center;
  }
}
/* stylelint-disable */
/* MailChimp Form Embed Code - Classic - 12/17/2015 v10.7 */
#mc_embed_signup form { position:relative; display:block; padding:10px 0 10px 3%; text-align:left; }
#mc_embed_signup h2 { padding:0; margin:15px 0; font-size:1.4em; font-weight:bold; }
#mc_embed_signup input { border: 1px solid #abb0b2; border-radius: 3px; }
#mc_embed_signup input[type=checkbox] { -webkit-appearance:checkbox; }
#mc_embed_signup input[type=radio] { -webkit-appearance:radio; }
#mc_embed_signup input:focus { border-color:#333; }
#mc_embed_signup .button { display: inline-block; width: auto; height: 32px; padding: 0 22px; margin: 0 5px 10px 0; clear:both; font-size:15px; font-weight: normal; line-height: 32px; color: #fff; text-align: center; text-decoration: none; white-space: nowrap; vertical-align: top; cursor: pointer; background-color: #aaa; border: 0 none; border-radius:4px; transition: all 0.23s ease-in-out 0s; }
#mc_embed_signup .button:hover { background-color:#777; }
#mc_embed_signup .small-meta { font-size: 11px; }
#mc_embed_signup .nowrap { white-space:nowrap; }

#mc_embed_signup .mc-field-group { position:relative; width:96%; min-height:50px; padding-bottom:3%; clear:left; }
#mc_embed_signup .size1of2 { display:inline-block; float:left; width:46%; margin-right:4%; clear:none; }
* html #mc_embed_signup .size1of2 { margin-right:2%; /* Fix for IE6 double margins. */ }
#mc_embed_signup .mc-field-group label { display:block; margin-bottom:3px; }
// #mc_embed_signup .mc-field-group input { display:block; width:100%; padding:8px 0; text-indent:2%; }
#mc_embed_signup .mc-field-group select { display:inline-block; width:99%; padding:5px 0; margin-bottom:2px; }

#mc_embed_signup .datefield,
#mc_embed_signup .phonefield-us { padding:5px 0; }

#mc_embed_signup .datefield input,
#mc_embed_signup .phonefield-us input { display:inline; width:60px; padding:5px 0 2px 0; margin:0 2px; text-align:center; letter-spacing:1px; }

#mc_embed_signup .phonefield-us .phonearea input,
#mc_embed_signup .phonefield-us .phonedetail1 input { width:40px; }

#mc_embed_signup .datefield .monthfield input,
#mc_embed_signup .datefield .dayfield input { width:30px; }

#mc_embed_signup .datefield label,
#mc_embed_signup .phonefield-us label { display:none; }

#mc_embed_signup .indicates-required { margin-right:4%; text-align:right; }
#mc_embed_signup .asterisk { position:relative; top:5px; font-size:150%; font-weight:normal; color:#e85c41; }
#mc_embed_signup .clear { clear:both; }

#mc_embed_signup .mc-field-group.input-group ul { padding:5px 0; margin:0; list-style:none; }
#mc_embed_signup .mc-field-group.input-group ul li { display:block; padding:3px 0; margin:0; }
#mc_embed_signup .mc-field-group.input-group label { display:inline; }
#mc_embed_signup .mc-field-group.input-group input { display:inline; width:auto; border:none; }

#mc_embed_signup div#mce-responses { top:-1.4em; float:left; width:90%; padding:0 0.5em 0 0.5em; margin: 0 5%; overflow:hidden; clear: both; }
#mc_embed_signup div.response { top:-1.5em; z-index:1; float:left; width:80%; padding:1em 0.5em 0.5em 0; margin:1em 0; font-weight:bold; }
#mc_embed_signup #mce-error-response { display:none; }
#mc_embed_signup #mce-success-response { display:none; color:#529214; }
#mc_embed_signup label.error { display:block; float:none; width:auto; padding:0.5em 0; margin-left:1.05em; text-align:left; }

// #mc-embedded-subscribe { display:block; width:auto; margin:1em 0 1em 5%; clear:both; }
#mc_embed_signup #num-subscribers { font-size:1.1em; }
#mc_embed_signup #num-subscribers span { padding:0.5em; margin-right:0.5em; font-weight:bold; border:1px solid #ccc; }

#mc_embed_signup #mc-embedded-subscribe-form div.mce_inline_error { z-index:1; display:inline-block; padding:5px 10px; margin:2px 0 1em 0; font-size:14px; font-weight:normal; color:#e85c41; background-color:rgba(255, 255, 255, 0.85); border-radius: 3px; }
#mc_embed_signup #mc-embedded-subscribe-form input.mce_inline_error { border:2px solid #e85c41; }

#mc_embed_signup {
  clear: left;
  // background: #fff;
}

#mc-embedded-subscribe-form input[type=checkbox] {
  display: inline;
  width: auto;
  margin-right: 10px;
}

#mergeRow-gdpr {
  margin-top: 20px;
}

#mergeRow-gdpr fieldset label {
  font-weight: normal;
}

#mc-embedded-subscribe-form .mc_fieldset {
  min-height: 0;
  padding-bottom: 0;
  border: none;
}

.content__gdpr label {
  margin-bottom: 40px;
}
/* stylelint-enable */

/**
 *Contact form
 */
input[type="text"],
input[type="email"],
select,
textarea {
  width: 100%;
  padding: .75em;
  margin-top: .375rem;
  margin-bottom: 1rem;
  resize: vertical;
  border: 1px solid #ccc;
  border-radius: .25rem;
}
