// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------
// Access all variables, mixins and functions in `abstracts` directory

@use '../abstracts/index' as *;

/**
 * Set up a decent box model on the root element
 * Enable smooth scrolling on the whole document
 */
html {
  scroll-behavior: smooth;
}

// Prevent jump links from being hidden under navbar with fixed position.
// https://css-tricks.com/fixed-headers-and-jump-links-the-solution-is-scroll-margin-top/
* {
  /* navbar background-color is transparent */
  scroll-margin-top: 1rem;

  @media only screen and (min-width: 768px) {
    /* navbar background-color is not transparent */
    // scroll-margin-top: 4.75rem;
    scroll-margin-top: 4.5rem;
  }
}

/**
 * Basic typography style for copy text
 */
body {
  font-family: $text-font-stack;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.5;
  color: $text-color;
  background-color: $body-bg-color;

  @media only screen and (min-width: 992px) {
    font-size: 1.125rem;
  }
}

/**
 * Basic styles for links
 */
a {
  color: $brand-color;
  text-decoration: none;
  border-bottom: 3px solid lime;

  &:focus {
    border: 1px dotted $white;
  }

  @include on-event {
    padding: 4px 8px;
    color: $white;
    background-color: $brand-color;
    border-bottom: none;
    // text-decoration: underline;
  }
}

.banner__heading a {
  border-bottom: none;

  @include on-event {
    padding: 0;
    color: $white;
    background-color: transparent;
    border: 2px solid $white;
    border-radius: 5px;

    &:focus {
      border: 2px dotted $white;
    }
  }
}

/**
 * Basic styles for font icons
 */
.icon {
  width: 5rem;
  height: 5rem;
}

li {
  margin-bottom: .3em;
}

/**
 * Basic styles for buttons
 */
.btn,
input[type="submit"] {
  padding: .5em 1em;
  font-size: 1rem;
  cursor: pointer;
  border: none;
}

.btn--primary,
input[type="submit"] {
  color: hsl(93, 51%, 10%);
  background-color: hsl(94, 50%, 55%);
  border: 1px solid mix($black, hsl(94, 50%, 55%), 5%);
  border-radius: 4px;

  &:focus {
    border: 1px dotted $white;
  }

  &:hover {
    color: $white;
    background-color: $bg-dark-green; // mix($black, $bg-dark-green, 1%);
  }
}

/**
 * Basic styles for forms.
 */

/**
 *Contact form
 */
input[type="text"],
input[type="email"],
select,
textarea {
  width: 100%;
  padding: .75em;
  margin-top: .375rem;
  margin-bottom: 1rem;
  resize: vertical;
  border: 1px solid #ccc;
  border-radius: .25rem;
}
