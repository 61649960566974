/**
 * header
 */

// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application (excuding the navbar).
// -----------------------------------------------------------------------------

@use '../abstracts/index' as *;

.banner {
  position: relative;
  max-width: 100%;
  height: 80vh;
  padding-bottom: 56.25%; // For responsive image.
  background-color: mix($white, hsl(93, 51%, 10%), 75%);
  // background-image: url('../img/group3slider.jpg');
  background-image: url('../img/group5.jpg');
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  background-blend-mode: multiply;

  .banner__heading {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-align: center;
  }
}
